import { BaseRoutes } from './baseRoutes';
import { navigateWithParams } from './microfrontends/shared/url/url';
import { ROUTES } from './routes';

const prefix = (location: Location, ...prefixes: string[]): boolean =>
  prefixes.some(
    (prefix) =>
      location.pathname === prefix || location.pathname === `${prefix}/`,
  );

const mfWelcome = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOS_SELLER + ROUTES.AUTO_EXTERNAL);

const mfImage = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOS_SELLER + ROUTES.CONTACT);

const mfContactPage = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOS_SELLER + ROUTES.CONTACT);

const mfFooter = (location: Location): boolean =>
  prefix(
    location,
    BaseRoutes.AUTOS_SELLER + ROUTES.AUTO_EXTERNAL,
    BaseRoutes.AUTOS_SELLER + ROUTES.CONTACT,
  );

const app = (): boolean => {
  if (
    location.pathname === BaseRoutes.AUTOS_SELLER ||
    location.pathname ===
      BaseRoutes.AUTOS_SELLER.slice(0, BaseRoutes.AUTOS_SELLER.length - 1) ||
    location.pathname === '/'
  ) {
    navigateWithParams(
      `${BaseRoutes.AUTOS_SELLER + ROUTES.AUTO_EXTERNAL}` || '',
    );
  }

  return true;
};

export default {
  app,
  mfFooter,
  mfWelcome,
  mfContactPage,
  mfImage,
};
