export enum ROUTES {
  AUTO_EXTERNAL = 'datos-vehiculo-externo',
  CONTACT = 'te-contactaremos',
  LANDING = 'https://www.creditas.mx',
}

export const GO_TO_ROUTES = {
  AUTO_EXTERNAL: ROUTES.LANDING,
  LANDING: 'https://www.creditas.mx',
};
