import { registerApplication, start } from 'single-spa';
import Activity from './activityFns';
import { BaseRoutes } from './baseRoutes';
import { GO_TO_ROUTES, ROUTES } from './routes';
import { ArrayImg } from './imagesDictionary';

const registerApps = (): void => {
  registerApplication(
    'mfNavbar',
    () => import('./microfrontends/mfNavbar/mfNavbar.lifecycle'),
    (): true => true,
    {
      logoGoTo: BaseRoutes.AUTOS_SELLER,
      baseUrl: BaseRoutes.AUTOS_SELLER,
    },
  );
  registerApplication(
    'mfWelcome',
    () => import('./microfrontends/mfWelcome/mfWelcome.lifecycle'),
    Activity.mfWelcome,
    {
      goTo: BaseRoutes.AUTOS_SELLER + ROUTES.CONTACT,
    },
  );
  registerApplication(
    'mfContactPage',
    () => import('./microfrontends/mfContactPage/mfContactPage.lifecycle'),
    Activity.mfContactPage,
    {
      goTo: GO_TO_ROUTES.LANDING,
    },
  );
  registerApplication(
    'mfImage',
    () => import('./microfrontends/mfImage/mfImage.lifecycle'),
    Activity.mfImage,
    {
      images: ArrayImg,
    },
  );
  registerApplication(
    'mfFooter',
    () => import('./microfrontends/mfFooter/mfFooter.lifecycle'),
    Activity.mfFooter,
  );
};
registerApplication(
  'app',
  () => import('./microfrontends/app.lifecycle'),
  Activity.app,
);
start();
registerApps();
