import { ROUTES } from './routes';

const imgUrl = `${process.env.PUBLIC_URL}/images`;

export const ArrayImg = {
  [ROUTES.CONTACT]: {
    desktop: `${imgUrl}/contactimg.png`,
    alt: 'Imagen de te contactarémos',
  },
};
