import { navigateToUrl } from 'single-spa';

export default (url: string, target?: string): void => {
  if (target === undefined && target !== '_self') {
    navigateToUrl(url);
  } else {
    window?.open(url, target);
  }
};

export const navigateWithParams = (url: string, extraParams?: string): void => {
  const queryParams = window.location.search;
  const addParams = queryParams === '' ? `?${extraParams}` : `&${extraParams}`;
  const newExtraParams = extraParams ? addParams : '';
  const goToUrl = `${url}${queryParams}${newExtraParams}`;
  navigateToUrl(goToUrl, '_self');
};
